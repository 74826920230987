<template>
  <b-card-code>
    <b-card-code class="bg-light-secondary mb-1" title="Search">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="row" style="width: 100%; padding: 5px">
          <b-form-input
            class="ml-1"
            id="name"
            v-model="name_filter"
            style="width: 34%"
            name="name"
            placeholder="Search with Nice Name"
          />
          <!-- <b-form-select
            class="ml-1"
            v-model="unit_filter"
            style="width: 30%"
            :options="unitOptions"
          /> -->
          <v-select
            v-model="unit_filter"
            multiple
            label="text"
            taggable
            class="ml-1"
            placeholder="--Select Unit--"
            :options="unitOptions"
            autocomplete
            style="width: 30%"
            :reduce="(unit_filter) => unit_filter.value"
          />
          <b-button
            style="width: 15%"
            class="ml-1"
            variant="secondary"
            @click="refreshFn"
            size="sm"
          >
            <span>
              <feather-icon icon="RefreshCwIcon" class="mr-50" />Reset</span
            >
          </b-button>
          <b-button
            style="width: 15%"
            class="ml-1"
            variant="primary"
            @click="load"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else
              ><feather-icon icon="SearchIcon" class="mr-50" />Search</span
            >
          </b-button>
        </div>
      </b-form>
    </b-card-code>
    <b-table responsive id="AgentTable" :fields="fields" :items="featureList">
      <template #cell(index)="data">
        {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      <template #cell(unit)="data">
        <b-badge
          variant="light-primary"
          class="text-capitalize"
          style="width: 100px"
          >{{ data.item.unit }}</b-badge
        >
      </template>
      <template #cell(action)="data">
        <div class="d-flex">
          <b-button
            style="width: 100px"
            variant="outline-warning"
            class=""
            size="sm"
            @click="editFeature(data)"
          >
            <feather-icon icon="EditIcon" class="" />
            Edit
          </b-button>
          <!-- <b-button
            style="width: 100px"
            variant="outline-danger"
            class="ml-1"
            size="sm"
            @click="gotoDelete(data.item.id, data.item.nice_name)"
          >
            <feather-icon icon="TrashIcon" class="" />
            Delete
          </b-button> -->
        </div>
      </template>
    </b-table>

    <b-sidebar
      id="sidebar-edit-feature"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="600px"
    >
      <!-- Header -->
      <FeatureDetail :featureData="featureData" :closeSidebar="closeSidebar" />
    </b-sidebar>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ nice_name }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteFeature()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  VBToggle,
  BSidebar,
  BRow,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BTab,
  BTabs,
  BFormCheckbox,
} from "bootstrap-vue";
import FeatureDetail from "./components/edit.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BRow,
    BForm,
    BFormInput,
    BFormSelect,

    BSpinner,
    BButton,
    BTab,
    BTabs,
    BFormCheckbox,
    BSidebar,
    FeatureDetail,
    vSelect,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      agent_types: [],
      featureList: [],
      fields: [
        { key: "index", label: "#" },
        { key: "nice_name", label: "Nice Name" },
        { key: "feature_code", label: "Feature Code" },
        { key: "description", label: "Description" },
        { key: "tariff", label: "Tariff" },
        { key: "unit", label: "Unit" },
        { key: "action", label: "Action" },
      ],
      featureData: {},
      name_filter: "",
      unit_filter: [],
      unitOptions: [
        // { value: null, text: "Select Unit" },
        { value: "credit", text: "Credit" },
        { value: "boolean", text: "Boolean" },
        { value: "number", text: "Number" },
      ],
      isloading: false,
      openDeleteModal:false,
      nice_name:"",
      feature_id:"",
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    refreshFn() {
      this.name_filter = "";
      this.unit_filter = [];
      this.load();
    },
    load: function () {
      this.isloading = true;
      let url = process.env.VUE_APP_BASEURL + "feature/features?query=true";
      if (this.name_filter != "") {
        url = url + "&nice_name=" + this.name_filter;
      }
      if (this.unit_filter.length != 0) {
        const queryString = this.unit_filter
          .map((item) => `unit=${item}`)
          .join("&");
        url = url + `&${queryString}`;
      }
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        this.featureList = res.data;
        this.isloading = false;
      });
    },
    editFeature(data) {
      this.featureData = data.item;
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-feature");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-feature");
      this.load();
    },
    gotoDelete(id, nice_name) {
      this.openDeleteModal = true;
      this.feature_id = id;
      this.nice_name = nice_name;
    },

    deleteFeature() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "feature/features/" +
          this.feature_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
      });
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.bg-background {
  background: #fff !important;
}
[dir] .dark-layout .bg-background {
  background: #283046 !important;
  border-color: #404656;
}
</style>
