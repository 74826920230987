<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
    <span class="font-weight-bold">{{featureData.nice_name}}</span>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <b-card class="w-100">
      <validation-observer ref="GroupAddForm" #default="{ invalid }">
        <b-form>
          <b-form-group label="Nice Name">
            <validation-provider
              #default="{ errors }"
              name="Nice Name"
              :rules="{
                required: true,
                regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
              }"
            >
              <b-form-input
                id="name"
                v-model="featureData.nice_name"
                placeholder="Nice Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Tariff">
            <b-form-input
              type="number"
              id="tariff"
              v-model="featureData.tariff"
              placeholder="Tariff"
            />
          </b-form-group>
          <b-form-group label="Unit">
            <b-form-select v-model="featureData.unit" :options="unitOptions" />
          </b-form-group>
          <b-form-group label="Description">
            <validation-provider
              #default="{ errors }"
              name="Description"
              :rules="{
                required: true,
                regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
              }"
            >
              <b-form-textarea
                name="description"
                v-model="featureData.description"
                placeholder="A brief description about the Feauture"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            variant="primary"
            @click="updateFeature"
            size="sm"
            :disabled="invalid"
          >
            <span v-if="isLoading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Update</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BButton,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    featureData: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      niceName: "",
      tarif: null,
      description: "",
      isLoading: false,
      unit_filter: "",
      unitOptions: [
        // { value: null, text: "Select Unit" },
        { value: "credit", text: "Credit" },
        { value: "boolean", text: "Boolean" },
        { value: "number", text: "Number" },
      ],
    };
  },
  created: function () {},
  methods: {
    updateFeature() {
      this.isLoading = true;
      let data = {
        nice_name: this.featureData.nice_name,
        description: this.featureData.description,
        tariff: this.featureData.tariff,
        unit: this.featureData.unit,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "feature/features/" +
          this.featureData.id +
          "/",
      };
      this.$http(options).then((res) => {
        // if (res.data.agent_type_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Edited Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
        // }
        this.isLoading = false;
        this.closeSidebar();
      });
    },
  },
};
</script>
