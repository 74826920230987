var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.featureData.nice_name))]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.closeSidebar()}}})],1)]),_c('b-card',{staticClass:"w-100"},[_c('validation-observer',{ref:"GroupAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label":"Nice Name"}},[_c('validation-provider',{attrs:{"name":"Nice Name","rules":{
              required: true,
              regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Nice Name"},model:{value:(_vm.featureData.nice_name),callback:function ($$v) {_vm.$set(_vm.featureData, "nice_name", $$v)},expression:"featureData.nice_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Tariff"}},[_c('b-form-input',{attrs:{"type":"number","id":"tariff","placeholder":"Tariff"},model:{value:(_vm.featureData.tariff),callback:function ($$v) {_vm.$set(_vm.featureData, "tariff", $$v)},expression:"featureData.tariff"}})],1),_c('b-form-group',{attrs:{"label":"Unit"}},[_c('b-form-select',{attrs:{"options":_vm.unitOptions},model:{value:(_vm.featureData.unit),callback:function ($$v) {_vm.$set(_vm.featureData, "unit", $$v)},expression:"featureData.unit"}})],1),_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":{
              required: true,
              regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"name":"description","placeholder":"A brief description about the Feauture"},model:{value:(_vm.featureData.description),callback:function ($$v) {_vm.$set(_vm.featureData, "description", $$v)},expression:"featureData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":invalid},on:{"click":_vm.updateFeature}},[(_vm.isLoading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Update")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }